import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoadingSpinner from './components/LoadingSpinner'

const Splash = lazy(() => import('./pages/Splash'))
const Login = lazy(() => import('./pages/Login'))
const Membership = lazy(() => import('./pages/Membership'))
const Profile = lazy(() => import('./pages/Profile'))
const FourOhFour = lazy(() => import('./errors/FourOhFour'))
const PlayViewer = lazy(() => import('./pages/PlayViewer'))
const PlayEditor = lazy(() => import('./pages/PlayEditor'))

const ScoutingList = lazy(() => import('./pages/ScoutingList'))
const ScoutingReport = lazy(() => import('./pages/ScoutingReport'))

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Switch>
      <Route exact path='/' render={() => <Splash />} />
      <Route exact path='/login' render={() => <Login {...props} />} />
      <Route exact path='/membership' render={() => <Membership {...props} />} />
      <Route exact path='/profile' render={() => <Profile {...props} />} />

      <Route exact path='/play/edit/:playId' render={({ match: { params: { playId } } }) => <PlayEditor playId={playId} {...props} />} />
      <Route exact path='/play' render={() => <PlayViewer {...props} />} />
      <Route exact path='/play/:playId' render={({ match: { params: { playId } } }) => <PlayViewer playId={playId} {...props} />} />

      <Route exact path='/scouting' render={() => <ScoutingList />} />
      <Route exact path='/scouting/:scoutingId' render={({ match: { params: { scoutingId } } }) => <ScoutingReport scoutingId={scoutingId} {...props} />} />

      {/* <Route exact path='/training' /> */}
      {/* <Route exact path='/training/:trainingId' /> */}
      {/* <Route exact path='/training/edit/:trainingId' /> */}

      <Route render={() => <FourOhFour />} />
    </Switch>
  </Suspense>
)
