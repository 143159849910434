import React, { Component } from 'react'
import ErrorBoundary from './errorReporting'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import apolloClient from './lib/apolloClient'
import Navbar from './components/Navbar'

import './App.css'
import Router from './routes'

import initFirebase from './lib/initFirebase'
const firebase = initFirebase()

class App extends Component {
  state = {
    token: null
  }

  componentDidMount () {
    const self = this
    this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
      self.setState({ token: user ? user.getIdToken() : null })
    })
  }

  componentWillUnmount () {
    this.unsubscribe && this.unsubscribe()
  }

  render () {
    const { token } = this.state

    return (
      <ErrorBoundary>
        <div>
          <ApolloProvider client={apolloClient()}>
            <BrowserRouter>
              <div className='app-container'>
                <Navbar token={token} />
                <Router token={token} />
              </div>
            </BrowserRouter>
          </ApolloProvider>
        </div>
      </ErrorBoundary>
    )
  }
}

export default App
