import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'
import React from 'react'

let ErrorBoundary

if (process.env.NODE_ENV === 'production') {
  const bugsnagClient = bugsnag('6f7acd28293188bd9f2df5a7d0f5e578')
  ErrorBoundary = bugsnagClient.use(createPlugin(React))
} else {
  ErrorBoundary = props => <div>{props.children}</div>
}

export default ErrorBoundary
