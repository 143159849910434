import React from 'react'
import { Spinner } from '@blueprintjs/core'

export default ({ marginTop, isBlock, size }) => {
  const marg = marginTop || '1em'
  const block = isBlock || false
  const s = size || 20
  return (
    <span style={{ marginTop: marg, display: block ? 'block' : 'inline' }}>
      <Spinner size={s} intent='primary' />
    </span>
  )
}
