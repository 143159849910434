/* @flow */

import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost'
import firebase from 'firebase/app'
import 'firebase/auth'
import { setContext } from 'apollo-link-context'
import { persistCache } from 'apollo-cache-persist'

import { FUNC_URL } from '../constants'

const createApolloClient = () => {
  const cache = new InMemoryCache()
  persistCache({
    cache,
    storage: window.sessionStorage
  })

  const httpLink = new HttpLink({
    uri: FUNC_URL
  })

  const authLink = setContext(async (_, { headers }) => {
    const user = firebase.auth().currentUser
    const token = user
      ? await user.getIdToken()
      : null

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const client = new ApolloClient({
    connectToDevTools: true,
    ssrMode: false,
    link: authLink.concat(httpLink),
    cache,
    shouldBatch: true
  })

  client.initQueryManager()
  // client.resetStore()

  return client
}

export default createApolloClient
