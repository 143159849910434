import firebase from 'firebase/app'
import 'firebase/auth'

let isInit = false

const initFirebase = () => {
  if (isInit) return firebase
  isInit = true

  let authDomain = 'playbook-next-dev.firebaseapp.com'
  if (window.location.hostname === 'nimbleplaybook.app') authDomain = 'nimbleplaybook.app'
  else if (window.location.hostname === 'nimbleplaybook.com') authDomain = 'nimbleplaybook.com'

  firebase.initializeApp({
    apiKey: 'AIzaSyChBqk5W5OBcsyrbDDNAj4VF5KfCie6_bM',
    authDomain
  })

  return firebase
}

export default initFirebase
