// /* global fetch */
/* @flow */

import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Link, withRouter } from 'react-router-dom'
import { Alignment, AnchorButton, Button, Divider, Menu, MenuItem, Navbar, Popover, Tooltip } from '@blueprintjs/core'

import { APP_TITLE_SHORT_NAME } from '../constants'

class NavbarImpl extends Component {
  state = { mobileView: window.innerWidth < 700 }

  componentDidMount = () => window.addEventListener('resize', this._handleNavbarResize)
  componentWillUnmount = () => window.removeEventListener('resize', this._handleNavbarResize)

  _handleNavbarResize = () => {
    this.setState({ mobileView: window.innerWidth < 700 })
  }

  _logout = async () => {
    firebase.auth().signOut()
    this.props.history.push('/')
  }

  _navigateTo = (path) => () => {
    this.props.history.push(path)
  }

  _renderMenu = (token, mobile) => (
    <>
      {token && <Button minimal icon='clipboard' text={mobile ? null : 'Plays'} onClick={this._navigateTo('/play')} />}

      {token && <Button minimal icon='id-number' text={mobile ? null : 'Scouting'} onClick={this._navigateTo('/scouting')} />}

      {token && <Tooltip content='Coming soon'>
        <AnchorButton minimal disabled icon='train' text={mobile ? null : 'Training'} onClick={this._navigateTo('/training')} />
      </Tooltip>}

      {token && <Divider />}

      {!token && <Button icon='log-in' minimal text='Log In' onClick={this._navigateTo('/login')} />}
      {token &&
        <Popover minimal position='bottom-right' inheritDarkTheme={false}>
          <Button large minimal icon='person' />
          <Menu>
            <MenuItem icon='person' text='Profile' onClick={this._navigateTo('/profile')} />
            <Divider />
            <MenuItem icon='log-in' text='Log Out' onClick={this._logout} />
          </Menu>
        </Popover>
      }
    </>
  )

  render () {
    const { token } = this.props
    const { mobileView } = this.state

    return (
      <Navbar className='bp3-dark' fixedToTop style={{ maxWidth: '100%' }}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Link to='/'>
              <svg width='100' height='45'>
                <rect x='0' y='10' width='68' height='24' fill='#ffcc00' />
                <text x='5' y='28' stroke='#394b59' fill='#394b59'>{APP_TITLE_SHORT_NAME.toUpperCase()}</text>
              </svg>
            </Link>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {this._renderMenu(token, mobileView)}
        </Navbar.Group>
      </Navbar>
    )
  }
}

export default withRouter(NavbarImpl)
